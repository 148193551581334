import React from 'react'
import Helmet from 'react-helmet'

import config from '../../data/site-config'

import Layout from '../components/layout'
import MainWrapper from '../components/MainWrapper/MainWrapper'
import PageHero from '../components/PageHero'
import TeamMembers from '../components/Team/TeamMembers'
import TeamMantra from '../components/Team/TeamMantra'

const TeamPage = () => (
  <Layout>
    <Helmet>
      <title>{`💪 Spacetime Team • Dallas Web Design & Development Agency`}</title>
      <meta name="description" content="We’re not like other Dallas Web Design agencies. We focus on building the right team on a per-project basis so you get the expertise you deserve." />
      <link rel="canonical" href={`${config.siteUrl}/team/`} />
    </Helmet>

    <PageHero>
      <h3 className="t-headline">Mostly ordinary, occasionally extraordinary&mdash;<span className="o-60">always delightful</span></h3>
      <h1 className="f5 fw4 lh-copy o-80">A Dallas web design agency in love with the&nbsp;<em>fantastic</em>.</h1>
    </PageHero>

    <MainWrapper>

      <section className="pv6 bg-fog-white">
        <div className="row flex justify-center flex-column items-center">
          <span className="t-small-caps red">&mdash;Give me the elevator pitch</span>
          <h3 className="fw4 lh-copy mw6 tc">At Spacetime, we partner with clients to strategize &amp; execute their vision. We approach problems with a business-centric focus with a goal of sustainability and&nbsp;ROI.</h3>
        </div>
      </section>

      <section className="pv5 bg-white">
        <div className="row">
          <TeamMembers />
        </div>
      </section>

      <section className="pv5 bg-fog-white">
        <div className="row">
          <TeamMantra />
        </div>
      </section>

    </MainWrapper>

  </Layout>
)

export default TeamPage
